import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { decrypt } from "../services/encrypt";
import { Logout, PlayCircleFilled, TopicRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { doc } from "@firebase/firestore";
import { db } from "../config/firebase";
import { getDoc } from "firebase/firestore";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VideoPlayer from "./VideoPlayer";
import Logo from "../assets/logo.png";
const drawerWidth = 280;

function ResponsiveDrawer(props) {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = React.useState(
    decrypt(sessionStorage.getItem("token"))
  );

  var user = JSON.parse(token);
  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const [videoUrl, setVideoUrl] = React.useState("");

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const [items, setItems] = React.useState([]);
  const [expandedCategories, setExpandedCategories] = React.useState({});

  const getData = async () => {
    var documentArray = [];
    for (var element in user.topics) {
      const docRef = doc(db, `topics/${user.topics[element]}`);
      const docSnap = await getDoc(docRef);

      const data = {
        id: user.topics[element],
        name: docSnap.data().name,
        videos: docSnap.data().videos,
      };
      documentArray.push(data);
    }
    setItems(documentArray);
  };

  const handleCategoryClick = (id) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const logout = [{ name: "Logout", path: "/logout", icon: <Logout /> }];

  React.useEffect(() => {
    getData();
  }, []);

  const drawer = (
    <div>
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="logo"
          style={{ width: drawerWidth - 20, marginInline: "auto" }}
          src={Logo}
        ></img>
      </Toolbar>
      <Divider />
      <List>
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <ListItemButton onClick={() => handleCategoryClick(item.id)}>
              <ListItemIcon>
                <TopicRounded style={{ color: "#412756" }} />
              </ListItemIcon>
              <ListItemText primary={item.name} />
              {expandedCategories[item.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={expandedCategories[item.id]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {item.videos.map((video) => (
                  <ListItem
                    sx={{ pl: 2 }}
                    onClick={() => {
                      setVideoUrl(video.url);
                    }}
                    key={video.url}
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon style={{ color: "#412756" }}>
                        <PlayCircleFilled />
                      </ListItemIcon>
                      <ListItemText primary={video.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
      <Divider />
      <List>
        {logout.map((item) => (
          <ListItem
            onClick={() => {
              navigate(item.path);
            }}
            key={item.path}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon style={{ color: "#412756" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#150648",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ color: "#faeaff" }}
            variant="h6"
            noWrap
            component="div"
          >
            Hi, {user.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            style: {
              backgroundColor: "#9e72c3",
              color: "#412756",
            },
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          PaperProps={{
            style: {
              backgroundColor: "#9e72c3",
              color: "#412756",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          backgroundColor: "#0f0529", // "#afa5d6",
          backgroundSize: "contain",
          color: "#e4e4e4",
        }}
      >
        <Toolbar />
        {videoUrl.length === 0 ? (
          <Typography>Please select a topic and video to continue</Typography>
        ) : (
          <VideoPlayer url={videoUrl} />
        )}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
